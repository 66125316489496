<template>
	<!-- 工单管理-居民用户 -->
	<div>
		<div class="content">
			<div class="all" v-if="!dialogVisible">
				<div class="table">
					<!--搜索框-->
					<el-form
						ref="search"
						:inline="true"
						:model="search"
						size="small"
						label-width="80px"
					>
						<el-form-item label="安检计划">
							<el-select
								v-model="search.periodIds"
								clearable
								collapse-tags
								placeholder="请选择"
								multiple
								filterable
								
							>
							<!-- @change="loadList(1)" -->
								<el-option
								v-for="item in periodLists"
								:key="item.id"
								:label="item.periodName"
								:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="用户分类">
							<el-select
								v-model="search.userTypeId"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in userTypes"
									:key="item.id"
									:label="item.name"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="片区">
							<el-cascader
								filterable
								clearable
								collapse-tags
								v-model="addrName"
								:options="taskAddrdata"
								:props="props"
								ref="pianqu"
							></el-cascader>
							<!-- <el-select
								v-model="search.addrName"
								clearable
								placeholder="请选择"
								@change="loadList(1)"
							>
								<el-option
									v-for="item in taskAddrdata"
									:key="item.addrId"
									:label="item.addrName"
									:value="item.addrName"
								>
								</el-option>
							</el-select> -->
						</el-form-item>
						<el-form-item label="安检员">
							<el-select
								v-model="search.inspectorId"
								clearable
								placeholder="请选择"
								multiple
								collapse-tags
								filterable
							>
								<el-option
									v-for="item in taskInspectorData"
									:key="item.id"
									:label="item.realname"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="安检结果">
							<el-select
								v-model="search.safetyResults"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in checkerList"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="审核状态">
							<el-select
								v-model="search.auditStatus"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in auditStatusList"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="问题登记">
							<el-select
								v-model="search.problem"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in problemList"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="工单状态">
							<el-select
								v-model="search.statusFlag"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in statusList"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="采集模板">
							<el-select v-model="search.tplId" clearable placeholder="请选择" filterable>
								<el-option
								v-for="item in taskTplIdData"
								:key="item.tplId"
								:label="item.tplName"
								:value="item.tplId"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="检查时间">
							<el-date-picker
								style="width: 230px"
								v-model="times"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd HH:mm:ss"
								:default-time="['00:00:00', '23:59:59']"
							>
							</el-date-picker>
						</el-form-item>
						<el-form-item label="">
							<el-input
								placeholder="请输入关键字"
								v-model="search.keyWordValue"
							>
								<el-select
									placeholder="请选择"
									v-model="search.keyWord"
									slot="prepend"
								>
									<el-option
										v-for="item in searchBar"
										:key="item.id"
										:label="item.label"
										:value="item.id"
									></el-option>
								</el-select>
							</el-input>
						</el-form-item>

						<el-form-item label-width="20px">
							<div>
								<el-button
									type="primary"
									size="small"
									@click="loadList"
									>查询</el-button
								>
								<el-button
									type="warning"
									size="small"
									@click="reset"
									>重置</el-button
								>
								<el-popover
									placement="right"
									width="200"
									trigger="click"
									v-model="exportVisible"
									@after-leave="excelName = ''">
									<el-input
										size="mini"
										placeholder="请输入导出文件名"
										v-model="excelName"
										class="exportInput">
									</el-input>
									<div style="text-align: right; margin: 0">
										<el-button size="mini" type="text" @click="exportVisible = false">取消</el-button>
										<el-button type="primary" size="mini" @click="exportFile">确定</el-button>
									</div>
									<el-button slot="reference"
										size="small" type="primary"
										style="margin-left:10px"
										v-if="$anthButtons.getButtonAuth('gdanguanlidaochu')">
										导出
									</el-button>
								</el-popover>
							</div>
						</el-form-item>
					</el-form>
					<div style="display: flex; margin-bottom: 10px">
						<el-button
               v-if="$anthButtons.getButtonAuth('jmsh')"
							type="text"
							style="
								border: 1px solid #1082ff;
								color: #1082ff;
								padding: 0 14px;
								height: 30px;
							"
							plain
							@click="audit()"
							size="small"
							>审核</el-button
						>
					</div>
					<!--数据表格-->
					<el-table
						ref="tableData"
						:data="tableData"
						style="width: 100%"
						border
						height="calc(100vh - 450px)"
						stripe
						:header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
						@selection-change="handleSelectionChange"
					>
						<el-table-column
							fixed="left"
							type="selection"
							width="50"
						>
						</el-table-column>
						<el-table-column type="index" label="序号" width="55">
						</el-table-column>
						
						<el-table-column
							prop="taskName"
							label="任务名称"
							width="120"
						>
              <template slot-scope="{row}">
                <el-tooltip class="item" effect="dark" :content="row.taskName" placement="top-start">
                    <div class="twoLines">{{row.taskName}}</div>
                </el-tooltip>
              </template>
						</el-table-column>
						<el-table-column
							prop="residentName"
							label="户主姓名"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="address"
							label="地址"
							width="200"
						>
							<template slot-scope="{ row }">
								<!-- <el-popover placement="top-start" trigger="hover" :content="row.address">
                  <div  slot="reference" class="twoLines">
                    {{row.address}}
                  </div>
                </el-popover> -->
								<el-tooltip
									class="item"
									effect="dark"
									:content="row.address"
									placement="top-start"
								>
									<div class="twoLines">
										{{ row.address }}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column
							prop="mobile"
							label="户主电话"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="inspectorName"
							label="安检员"
							width="120"
						>
							<template slot-scope="{ row }">
								<div class="twoLines">
									<p>
										{{ row.inspectorName }}
										<br />
										<span v-if="row.inspectorMobile"
											>({{ row.inspectorMobile }})</span
										>
									</p>
								</div>
								<!-- </el-tooltip> -->
							</template>
						</el-table-column>
						<el-table-column
							prop="checkTime"
							label="检查时间"
							width="160"
						></el-table-column>
						<el-table-column
							prop="inspectionTime"
							label="检查时长"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						
						<el-table-column
							prop="userTypeName"
							label="用户分类"
							width="120"
						>
						</el-table-column>
						<el-table-column
							prop="addrName"
							label="片区"
							width="120"
						>
							<template slot-scope="{ row }">
								<!-- <el-popover placement="top-start" trigger="hover" :content="row.addrName">
                  <div  slot="reference" class="twoLines">
                    {{row.addrName}}
                  </div>
                </el-popover> -->
								<el-tooltip
									class="item"
									effect="dark"
									:content="
										row.addrName + '->' + row.addrsName+ '->' + row.addrssName
									"
									placement="top-start"
								>
									<div class="twoLines">
										{{ row.addrName }}->{{ row.addrsName }}->{{ row.addrssName }}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						
						<el-table-column
							prop="dataCode"
							width="170"
							label="工单编号"
						>
							<!-- <template slot-scope="{row}"> -->
							<!-- <el-popover placement="top-start" trigger="hover" :content="row.dataCode">
                  <div  slot="reference" class="twoLines">
                    {{row.dataCode}}
                  </div>
                </el-popover> -->
							<!-- <el-tooltip class="item" effect="dark" :content="row.dataCode" placement="top-start">
                  <div class="twoLines">{{row.dataCode}}</div>
                </el-tooltip>
              </template> -->
						</el-table-column>
						<el-table-column
							prop="deptName"
							label="负责部门"
							show-overflow-tooltip
							width="100"
						>
						</el-table-column>
						
						
						<el-table-column
							prop="reviewResults"
							label="复查结果"
							width="120"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											reviewResultsData,
											row.reviewResults,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							prop="reviewerName"
							label="复查人员"
							width="120"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<div class="twoLines">
									<p>
										{{ row.reviewerName }}
										<br />
										<span v-if="row.reviewerMobile"
											>({{ row.reviewerMobile }})</span
										>
									</p>
								</div>
								<!-- </el-tooltip> -->
							</template>
						</el-table-column>
						<el-table-column
							prop="reviewCheckTime"
							label="复查时间"
							width="160"
						></el-table-column>
						<el-table-column
							prop="reviewInspectionTime"
							label="复查时长"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="auditStatus"
							label="审核状态"
							width="120"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											auditStatusList,
											row.auditStatus,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							prop="explainState"
							label="说明"
							width="120"
						>
              <template slot-scope="{row}">
                <el-tooltip class="item" effect="dark" :content="row.explainState" placement="top-start">
                  <div class="twoLines">{{row.explainState}}</div>
                </el-tooltip>
              </template>
						</el-table-column>
						<el-table-column
							prop="auditBy"
							label="审核人"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>

						<el-table-column
							width="120"
							fixed="right"
							prop="problem"
							label="问题登记"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<p v-if="row.safetyResults==2|| row.safetyResults==3"></p>
								<p v-else>
									{{
										getDictLabel(
											problemLists,
											row.problem,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							fixed="right"
							prop="safetyResults"
							label="安检结果"
							width="80"
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											checkerList,
											row.safetyResults,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							fixed="right"
							prop="statusFlag"
							label="工单状态"
							width="90"
						>
							<template slot-scope="{ row }">
								<el-tag
									:type="
										row.statusFlag == '1'
											? 'success'
											: 'danger'
									"
									>{{
										getDictLabel(
											statusList,
											row.statusFlag,
											"id",
											"label"
										)
									}}</el-tag
								>
							</template>
						</el-table-column>

						<el-table-column
							fixed="right"
							prop="operation"
							label="操作"
							align="left"
							width="180"
						>
							<template slot-scope="{ row }">
								<el-button
                  v-if="$anthButtons.getButtonAuth('jmck')"
									type="primary"
									size="small"
									@click="addItem(3, row)"
									>查看</el-button
								>
								<el-button
                    v-if="$anthButtons.getButtonAuth('jmzd')"
									type="primary"
									size="small"
									@click="getTop(row)"
									>{{
										row.isTop == 1 ? "取消置顶" : "置顶"
									}}</el-button
								>
							</template>
						</el-table-column>
					</el-table>
					<div class="pageBox">
						<el-pagination
							:current-page="search.current"
							:background="true"
							:page-sizes="[30, 50, 100]"
							:page-size="search.size"
							layout="total, prev, pager, next, sizes, jumper"
							:total="total"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
						>
						</el-pagination>
					</div>
				</div>
				<div v-show="dialogVisible" style="height: 100%">
					<unDetail></unDetail>
				</div>
			</div>
		</div>
		<el-dialog title="导出中..." :visible.sync="deriveVisible" width="30%">
			<el-table v-loading="loading" empty-text="导出中"> </el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:visible.sync="dialogBatch"
			width="400px"
			:close-on-click-modal="false"
			@close="handleClose"
		>
			<el-form
				class="SearchBox"
				ref="Batchform"
				:model="Batchform"
				:rules="batchRule"
			>
				<el-form-item label="审核状态:" label-width="90px" prop="audit">
					<el-radio-group v-model="Batchform.audit">
						<el-radio :label="1">审核</el-radio>
						<el-radio :label="2">驳回</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="说明:" label-width="90px">
					<el-input
						type="textarea"
						:rows="2"
						placeholder="请输入内容"
						maxlength="200"
						show-word-limit
						v-model="Batchform.explainState"
					>
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button
					style="margin-right: 10px"
					@click="dialogBatch = false"
					>取消</el-button
				>
				<el-button type="primary" @click="submitBatch('Batchform')"
					>确定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>
 
<script>
import { periodList } from "../../RequestPort/selectQuery/residentRequest.js";
import mixins from '../earlier/mixin/mixins.js'
	import {
		getResidentWorkData,
		addrDropDown1,
		getAddrDropDown,
		dataBatchAudit,
		getDataTop,
		inspectorDropDown1,
		dataExportExcel,
		taskTplIdList
	} from "@/RequestPort/workorder/maintenRecord";
	import { selectDictListByPid } from "@/RequestPort/device/deviceList";
	import unDetail from "./component/details";
	import { getDictLabel } from "@/utils/common";
	import { exportMethod } from "../../common/js/exportExcel";
	import {templatePageList} from "../../RequestPort/peopleUser/people";
	export default {
		components: { unDetail },
		mixins:[mixins],
		data() {
			return {
				getDictLabel,
				search: {
					current: 1,
					size: 100,
					keyWord: 5,
				},
				addrName: [],
				props: {
					value: "trueId",
					label: "name",
					checkStrictly: false,
					emitPath: false,
					multiple: true
				},
				// 审核表单数据
				Batchform: {
					audit: null,
					explainState: null,
				},
				// 审核弹窗
				dialogBatch: false,
				total: 0,
				dialogVisible: false,
				// 列表数据
				tableData: [],
				// 复选的选中数据
				checkAll: [],
				// 用户分类
				userTypes: [],
				// 片区下拉
				taskAddrdata: [],
				// 安检员下拉
				taskInspectorData: [],
				batchRule: {
					audit: [{ required: true, message: "请选择", trigger: "blur" }],
				},
				// 安检结果
				checkerList: [
					{ label: "正常安检", id: 1 },
					{ label: "到访不遇", id: 2 },
					{ label: "拒绝安检", id: 3 }
				],
				auditStatusList: [
					{ label: "未审核", id: 1 },
					{ label: "已审核", id: 2 },
					{ label: "驳回", id: 3 },
				],
				reviewResultsData: [
					{ label: "正常复查", id: 1 },
					{ label: "到访不遇", id: 2 },
				],
				problemList: [
					{ label: "无问题", id: "1" },
					{ label: "有问题未处理", id: "2" },
					{ label: "问题已处理", id: "3" },
				],
				problemLists: [
					{ label: "无问题", id: "1" },
					{ label: "有问题未处理", id: "2" },
					{ label: "问题已处理", id: "3" },
				],
				// 工单状态
				statusList: [
					{ label: "已完成", id: 1 },
					{ label: "未完成", id: 2 },
					{ label: "待复查", id: 3 },
				],
				// 采集模板下拉
				taskTplIdData: [],
				// 关键字条件
				searchBar: [
					{ label: "户主姓名", id: 1, name: "residentName" },
					{ label: "安检联系人", id: 2, name: "contactName" },
					{ label: "电话", id: 3, name: "mobile" },
					{ label: "地址", id: 4, name: "address" },
					{ label: "工单编号", id: 5, name: "dataCode" },
					{ label: "任务名称", id: 6, name: "taskName" },
				],
				// 时间
				times: [],
				// 导出弹窗
				editConductVisible: false,
				loading: true,
				periodLists: [], // 安检周期下拉
				// 导出
				deriveVisible: false,
				excelName: '',
				exportVisible: false
			};
		},
		mounted() {
			let date = new Date()
			// let today = `${date.getFullYear()}-${
			// 	date.getMonth() > 10 ? date.getMonth() : '0' + (date.getMonth() + 1)
			// }-${date.getDate()} 00:00:00`
			// let today1 = `${date.getFullYear()}-${
			// 	date.getMonth() > 10 ? date.getMonth() : '0' + (date.getMonth() + 1)
			// }-${date.getDate()} 23:59:59`
			this.getTimeFn()
			if (this.$route.query.taskCode) {
				this.search.taskCode = this.$route.query.taskCode;
			}
			// 获取列表数据
			this.loadList();
			// 用户分类
			this.getuserTypeDropDown();
			// 片区下拉
			this.gettaskAddrList();
			
			// 安检周期下拉
			this.getPeriod()
			// 采集模板下拉
			this.gettaskTplIdList()
			// 安检员下拉
			this.gettaskInspectorList();
		},
		methods: {
			//设置默认时间
			getTimeFn() {
				const that = this;
				const end = new Date();
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 6); //15天把7改掉
				this.times = [that.formatDate(start) + ' 00:00:00',that.formatDate(end) + ' 23:59:59']
			},
			exportFile() { // 导出
				if(!this.search.tplId){
					this.$message.error('请选择采集模板');
					return
				}
				this.exportVisible = false
				this.$message.success('正在下载中，完成后会有消息提醒，请稍等')
				// this.deriveVisible = true
				// 关键字处理
				this.searchBar.forEach((el) => {
					if (el.id == this.search.keyWord) {
						this.$set(this.search, el.name, this.search.keyWordValue);
					} else {
						delete this.search[el.name];
					}
				});
				// 片区处理
				if (this.addrName && this.addrName.length > 0) {
					console.log(this.$refs.pianqu.getCheckedNodes(true))
					// console.log(this.addrName)
					this.search.addrssId = this.$refs.pianqu.getCheckedNodes(true).map(item => {
						return item.data.trueId
					})
				} else {
					delete this.search.addrId;
					delete this.search.addrssId;
				}
				// 时间处理
				if (this.times && this.times.length > 0) {
					this.search.checkTimeStart = this.times[0];
					this.search.checkTimeEnd = this.times[1];
				} else {
					delete this.search.checkTimeStart;
					delete this.search.checkTimeEnd;
				}
				let searchObj = {}
				for(let key in this.search) {
					//  && key != 'addrId' && key != 'addrssId'
					if (key != 'size' && key != 'current' && key != 'keyWord' && key != 'tplId') {
						searchObj[key] = this.search[key] && this.search[key].length ? this.search[key].toString() : '';
						// console.log(String(this.search[key]))
					} else {
						searchObj[key] = this.search[key]
					}
				}
				let obj = {excelName: this.excelName,...searchObj}
				dataExportExcel(obj).then(res => {
					// this.deriveVisible = false
					// exportMethod(res,'居民工单管理')
					// this.$store.commit('setCancelTokenList',null)
				})
			},
			cancel(){
				this.deriveVisible = false
				// if(!this.editConductVisible){
					// this.$store.getters.cancelTokenList('取消接口')
					window.stop()
				// }
			},
			getPeriod() { // 安检周期下拉
				periodList().then(res => {
					this.periodLists = res.data
				})
			},
			fillterName(name) {
				return name.split("(");
			},
			close(val) {
				this.dialogVisible = !this.dialogVisible; //监听值发生变化就关闭它
			},
			/**
			 * 重置
			 */
			reset() {
				this.search = {
					current: 1,
					size: 100,
					keyWord: 5,
					inspectorId: []
				};
				this.times = [];
				this.addrName = [];
				this.loadList();
			},
			/**
			 * 安检员下拉
			 */
			gettaskInspectorList() {
				inspectorDropDown1().then((res) => {
					this.taskInspectorData = res.data;
				});
			},
			/**
			 * 片区下拉
			 */
			gettaskAddrList() {
				addrDropDown1().then((res) => {
					this.taskAddrdata = res.data;
				});
			},
			/***
			 * 用户分类
			 */
			getuserTypeDropDown() {
				selectDictListByPid({ pid: 732 }).then((res) => {
					this.userTypes = res.data;
				});
			},

			/**
			 * 更换条数
			 */
			handleSizeChange(val) {
				this.search.current = 1;
				this.search.size = val;
				this.loadList();
			},
			/**
			 * 更换页码
			 */
			handleCurrentChange(val) {
				this.search.current = val;
				this.loadList();
			},
			handleSelectionChange(val) {
				this.checkAll = val;
			},
			// 关闭审核弹窗
			handleClose() {
				this.Batchform = { audit: null, explainState: null };
				this.dialogBatch = false;
			},
			audit() {
				let that = this;
				if (this.checkAll.length == 0) {
					that.$message.error("请先勾选数据");
					return;
				}
				this.Batchform.audit = 1;
				this.dialogBatch = true;
			},
			// 提交审核
			submitBatch(form) {
				this.$refs[form].validate((valid) => {
					if (!valid) return false;
					let arr = [];
					this.checkAll.forEach((item) => {
						arr.push(item.dataId);
					});
					this.Batchform.dataIds = arr;
					dataBatchAudit(this.Batchform)
						.then((res) => {
							if (res.code === 200) {
								this.$message.success(res.data);
								this.loadList();
							}
							this.dialogBatch = false;
						})
						.catch((e) => {
							this.loadList();
							this.dialogBatch = false;
						});
				});
			},
			/**
			 * 获取列表数据
			 */
			loadList(val) {
				// console.log(this.addrName);
				// console.log(this.$refs['pianqu'].getCheckedNodes())
				let searchObj = {}
				if (val) {
					this.search.current = 1;
				}
				
				// this.search.periodIds = this.zhouqiId.toString();
				// 关键字处理
				this.searchBar.forEach((el) => {
					if (el.id == this.search.keyWord) {
						this.$set(this.search, el.name, this.search.keyWordValue);
					} else {
						delete this.search[el.name];
					}
				});
				// 片区处理
				if (this.addrName && this.addrName.length > 0) {
					// console.log(this.$refs.pianqu.getCheckedNodes(true))
					// console.log(this.addrName)
					// this.search.addrssId = this.$refs.pianqu.getCheckedNodes(true).map(item => {
					// 	return item.data.trueId
					// })
					this.search.addrssId = this.addrName
					// console.log(this.search.addrssId,"是晒")
					// if (this.addrName.length >= 2) {
					// 	this.search.addrssId = this.addrName[1];
					// 	// this.search.addrId = this.addrName[0];
					// 	delete this.search.addrId;
					// } else {
					// 	this.search.addrId = this.addrName[0];
					// 	delete this.search.addrssId;
					// }
				} else {
					delete this.search.addrId;
					delete this.search.addrssId;
				}
				// 时间处理
				if (this.times && this.times.length > 0) {
					this.search.checkTimeStart = this.times[0];
					this.search.checkTimeEnd = this.times[1];
				} else {
					delete this.search.checkTimeStart;
					delete this.search.checkTimeEnd;
				}
				for(let key in this.search) {
					//  && key != 'addrId' && key != 'addrssId'
					if (key != 'size' && key != 'current' && key != 'keyWord' && key != 'tplId') {
						searchObj[key] = this.search[key] && this.search[key].length ? this.search[key].toString() : '';
						// console.log(String(this.search[key]))
					} else {
						searchObj[key] = this.search[key]
					}
				}
						console.log(searchObj)
				// this.search.inspectorId = this.search.inspectorId.toString();
				getResidentWorkData(searchObj).then((res) => {
					// for(let key in this.search) {
					// 	if (key != 'size' && key != 'current' && key != 'keyWord' && key != 'periodIds') {
					// 		this.search[key] = this.search[key] ? this.search[key].split(',') : []
					// 		this.search[key] = String(this.search[key])
					// 		console.log(String(this.search[key]))
					// 	}
					// }
					this.tableData = res.data.records;
					this.total = res.data.total;
					// this.search.inspectorId = this.search.inspectorId.length > 0 ? this.search.inspectorId.split(',') : []
				});
			},
			getTop(row) {
				getDataTop({ dataId: row.dataId }).then((res) => {
					this.$message.success(res.data);
					this.loadList();
				});
			},
			/**
			 * 采集模板下拉
			 */
			gettaskTplIdList(){
				templatePageList({
					size: -1,
					generalStatus: 1
				}).then(res=>{
					res.data.records.forEach(item => {
						this.taskTplIdData.push({
							tplName: item.tplName,
							tplId: Number(item.id)
						})
					})
				})
			},
			/**
			 * 查看页面
			 */
			addItem(index, row) {
				const routeData = this.$router.resolve({
					path: '/home/workorder/civilDetails',      //跳转目标窗口的地址
					query: {
						dataId: row.dataId,
						type: (row.safetyResults==1 && row.statusFlag==1) || row.finishType == 3 ? 1 : 0,
						taskId: row.taskId,
						residentId: row.residentId,
						safetyResults: row.safetyResults,
						auditStatus: row.auditStatus,
					} 
				})
				window.open(routeData.href);
				// this.$router.push({
				// 	path: "/home/workorder/civilDetails",
				// 	query: {
				// 		dataId: row.dataId,
				// 		type: row.finishType == 3 ? 1 : 0,
				// 		taskId: row.taskId,
				// 		residentId: row.residentId,
				// 		safetyResults: row.safetyResults,
				// 		auditStatus: row.auditStatus,
				// 	},
				// });
			},
		},
	};
</script>
 
<style scoped lang="less">
	::v-deep .el-input-group__prepend {
		width: 67px;
	}
	.table {
		padding: 10px;
		border-radius: 5px;
		margin: 10px;
		background-color: #ffffff;
	}
	.all {
		background-color: #f3f4f8;
		height: auto;
	}
	.content {
		background: #f3f4f8;
		width: 100%;
		border-radius: 6px;
		height: calc(100vh - 150px);
	}
	.exportInput{
		margin: 10px 0;
	}
	/deep/.el-cascader__search-input {
		min-width: 50px;
	}
</style>