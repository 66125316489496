import axios from '@/common/js/request'

// 民用任务分页列表
export function arumTaskTaskPageList(data){
    const arumTaskTaskPageList = axios({
        url: '/arumTask/taskPageList',
        method: 'get',
        params:data
    })
    return arumTaskTaskPageList
}
// 任务关闭
export function arumTaskTaskCloseById(data){
    const arumTaskTaskCloseById = axios({
        url: '/arumTask/taskCloseById',
        method: 'get',
        params:data
    })
    return arumTaskTaskCloseById
}
// 采集模板下拉
export function arumTaskTemplatePulldown(data){
    const arumTaskTemplatePulldown = axios({
        url: '/arumTask/templatePulldown',
        method: 'get',
        params:data
    })
    return arumTaskTemplatePulldown
}
// 安检周期下拉列表
export function periodList(data){
    const periodList = axios({
        url: '/arumPeriod/periodList',
        method: 'get',
        params:data
    })
    return periodList
}
// 安检模板下拉
export function arumTaskScuTemplatePulldown(data){
    const arumTaskScuTemplatePulldown = axios({
        url: '/arumTask/scuTemplatePulldown',
        method: 'get',
        params:data
    })
    return arumTaskScuTemplatePulldown
}
// 任务用户树（传taskId回显）
export function arumTaskFindUserTreeEcho(data){
    const arumTaskFindUserTreeEcho = axios({
        url: '/arumTask/findUserTreeEcho',
        method: 'get',
        params:data
    })
    return arumTaskFindUserTreeEcho
}
// 任务新增或修改（未开始任务）
export function arumTaskTaskInsertOrUpdate(data){
    const arumTaskTaskInsertOrUpdate = axios({
        url: '/arumTask/taskInsertOrUpdate',
        method: 'post',
        data:data
    })
    return arumTaskTaskInsertOrUpdate
}


// 任务新增或修改（未开始任务）
export function arumTaskSelectTaskById(data){
    const arumTaskSelectTaskById = axios({
        url: '/arumTask/selectTaskById',
        method: 'get',
        params:data
    })
    return arumTaskSelectTaskById
}

// 任务查看用户分页
export function arumTaskFindUsersByTaskIdAndAddrId(data){
    const arumTaskFindUsersByTaskIdAndAddrId = axios({
        url: '/arumTask/findUsersByTaskIdAndAddrId',
        method: 'get',
        params:data
    })
    return arumTaskFindUsersByTaskIdAndAddrId
}

// 任务片区用户数量
export function arumTaskFindAddrByArumUsers(data){
    const arumTaskFindAddrByArumUsers = axios({
        url: '/arumTask/findAddrByArumUsers',
        method: 'post',
        data:data
    })
    return arumTaskFindAddrByArumUsers
}

// 任务删除
export function arumTaskTaskDeleteById(data){
    const arumTaskTaskDeleteById = axios({
        url: '/arumTask/taskDeleteById',
        method: 'get',
        params:data
    })
    return arumTaskTaskDeleteById
}

// 任务修改（进行中任务）
export function arumTaskTaskUpdate(data){
    const arumTaskTaskUpdate = axios({
        url: '/arumTask/taskUpdate',
        method: 'post',
        data:data
    })
    return arumTaskTaskUpdate
}

// 常规区域安检任务新增或修改（未开始任务）
export function normalAddrTaskInsertOrUpdate(data){
    const normalAddrTaskInsertOrUpdate = axios({
        url: '/arumTask/normalAddrTaskInsertOrUpdate',
        method: 'post',
        data:data
    })
    return normalAddrTaskInsertOrUpdate
}
// 常规散户及异常安检任务新增或修改（未开始任务）
export function normalPartTaskInsertOrUpdate(data){
    const normalPartTaskInsertOrUpdate = axios({
        url: '/arumTask/normalPartTaskInsertOrUpdate',
        method: 'post',
        data:data
    })
    return normalPartTaskInsertOrUpdate
}


// 片区管理左侧树
export function arumAddrFindTree(data){
    const arumAddrFindTree = axios({
        url: '/arumAddr/findTree',
        method: 'get',
        params:data
    })
    return arumAddrFindTree
}
// 片区管理左侧树
export function arumAddrFindTreeNew(data){
    const arumAddrFindTree = axios({
        url: '/arumTask/taskFindTree',
        method: 'get',
        params:data
    })
    return arumAddrFindTree
}

// 常规区域安检区域分页查询
export function normalTaskAddrsPageList(data){
    const normalTaskAddrsPageList = axios({
        url: '/arumTask/normalTaskAddrsPageList',
        method: 'get',
        params:data
    })
    return normalTaskAddrsPageList
}

// 常规区域安检任务回显片区分页列表
export function normalAddrTaskPageList(data){
    const normalAddrTaskPageList = axios({
        url: '/arumTask/normalAddrTaskPageList',
        method: 'get',
        params:data
    })
    return normalAddrTaskPageList
}

// 任务查看详情片区详情分页列表
export function taskDetailAddrPageList(data){
    const taskDetailAddrPageList = axios({
        url: '/arumTask/taskDetailAddrPageList',
        method: 'get',
        params:data
    })
    return taskDetailAddrPageList
}
// 任务查看详情用户详情分页列表
export function taskDetailUserPageList(data){
    const taskDetailUserPageList = axios({
        url: '/arumTask/taskDetailUserPageList',
        method: 'get',
        params:data
    })
    return taskDetailUserPageList
}

// 常规散户安检用户分页查询
export function normalPartTaskuserPageList(data){
    const normalPartTaskuserPageList = axios({
        url: '/arumTask/normalPartTaskuserPageList',
        method: 'get',
        params:data
    })
    return normalPartTaskuserPageList
}

// 异常安检用户分页查询
export function unusualPartTaskuserPageList(data){
    const unusualPartTaskuserPageList = axios({
        url: '/arumTask/unusualPartTaskuserPageList',
        method: 'get',
        params:data
    })
    return unusualPartTaskuserPageList
}

// 任务查看详情用户详情列表导出excel
export function taskDetailUserListExportExcel(data) {
    const taskDetailUserListExportExcel = axios({
        url: '/arumTask/taskDetailUserListExportExcel',
        method: 'get',
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob', //改变接收的值类型
        params: data,
        isloading: true
    })
    return taskDetailUserListExportExcel
}